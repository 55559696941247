<template>
  <div class="Yuyan">
    <div class="banner">
      <div class="container">
      </div>
    </div>
    <div class="cont pd40">
      <div class="container">
        <div class="headTit">
          <h3>Chinese Language <span>Training Center</span></h3>
        </div>
        <div class="ps">We put the improvement of learners' actual language communication ability in the first place, and require each teacher to select practical language materials in life and work and adopt various teaching activities in class, so as to form a close to real language environment as far as possible, and encourage learners to actively use the target language through the well-constructed language tasks and the arranged teaching activities. </div>
        <el-tabs v-model="activeName">
          <el-tab-pane label="Standard Chinese Courses"
                       name="first">
            <div class="tit">
              Standard Chinese Courses
            </div>
            <div class="_table">
              <el-table :data="tableData1"
                        border
                        :show-header="false"
                        :row-class-name="tableRowClassName"
                        style="width: 100%">
                <el-table-column align="center"
                                 width="100"
                                 prop="val1"> </el-table-column>
                <el-table-column align="center"
                                 width="250"
                                 prop="val2"> </el-table-column>
                <el-table-column align="center"
                                 width="125"
                                 prop="val3"> </el-table-column>
                <el-table-column align="center"
                                 width="75"
                                 prop="val4"> </el-table-column>
                <el-table-column prop="val5"> </el-table-column>
                <el-table-column align="center"
                                 width="80"
                                 prop="val6"> </el-table-column>
                <el-table-column align="center"
                                 width="80"
                                 prop="val7"> </el-table-column>
              </el-table>
              <div class="_row">

                <el-popover placement="bottom"
                            title="Contact"
                            :width="300"
                            trigger="click">
                  <p>Tel: 86-10-62040776</p>
                  <p>Email: zhuxueedu@gmail.com</p>
                  <template #reference>
                    <a href="javascript:;">Consult now</a>
                  </template>
                </el-popover>
                <a href="javascript:;"
                   @click="onShowForm('Standard Chinese Courses')">Enrol now</a>
                <p>Support <strong>Skype</strong> and <strong> Wechat </strong> Teaching</p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Chinese Character Courses"
                       name="second">
            <div class="tit">
              Chinese Character Courses
            </div>
            <div class="_table">
              <el-table :data="tableData2"
                        border
                        :show-header="false"
                        :row-class-name="tableRowClassName"
                        style="width: 100%">
                <el-table-column align="center"
                                 width="100"
                                 prop="val1"> </el-table-column>
                <el-table-column align="center"
                                 width="250"
                                 prop="val2"> </el-table-column>
                <el-table-column align="center"
                                 width="125"
                                 prop="val3"> </el-table-column>
                <el-table-column align="center"
                                 width="75"
                                 prop="val4"> </el-table-column>
                <el-table-column prop="val5"> </el-table-column>
                <el-table-column align="center"
                                 width="80"
                                 prop="val6"> </el-table-column>
                <el-table-column align="center"
                                 width="80"
                                 prop="val7"> </el-table-column>
              </el-table>
              <div class="_row">
                <el-popover placement="bottom"
                            title="Contact"
                            :width="300"
                            trigger="click">
                  <p>Tel: 86-10-62040776</p>
                  <p>Email: zhuxueedu@gmail.com</p>
                  <template #reference>
                    <a href="javascript:;">Consult now</a>
                  </template>
                </el-popover>
                <a href="javascript:;"
                   @click="onShowForm('Chinese Character Courses')">Enrol now</a>
                <p>Support <strong>Skype</strong> and <strong> Wechat </strong> Teaching</p>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Business Chinese Courses"
                       name="third">
            <div class="tit">
              Business Chinese Courses
            </div>
            <div class="_table">
              <el-table :data="tableData3"
                        border
                        :show-header="false"
                        :row-class-name="tableRowClassName"
                        style="width: 100%">
                <el-table-column align="center"
                                 width="100"
                                 prop="val1"> </el-table-column>
                <el-table-column align="center"
                                 width="250"
                                 prop="val2"> </el-table-column>
                <el-table-column align="center"
                                 width="125"
                                 prop="val3"> </el-table-column>
                <el-table-column align="center"
                                 width="75"
                                 prop="val4"> </el-table-column>
                <el-table-column prop="val5"> </el-table-column>
                <el-table-column align="center"
                                 width="80"
                                 prop="val6"> </el-table-column>
                <el-table-column align="center"
                                 width="80"
                                 prop="val7"> </el-table-column>
              </el-table>
              <div class="_row">
                <el-popover placement="bottom"
                            title="Contact"
                            :width="300"
                            trigger="click">
                  <p>Tel: 86-10-62040776</p>
                  <p>Email: zhuxueedu@gmail.com</p>
                  <template #reference>
                    <a href="javascript:;">Consult now</a>
                  </template>
                </el-popover>
                <a href="javascript:;"
                   @click="onShowForm('Business Chinese Courses')">Enrol now</a>
                <p>Support <strong>Skype</strong> and <strong> Wechat </strong> Teaching</p>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>

        <div class="headTit"
             style="margin-top:40px;">
          <h3>Select <span>Class</span></h3>
        </div>

        <el-row :gutter="24">
          <el-col :span="8">
            <div class="lis">
              <div class="imgbox">
                <img src="@/assets/A01.jpg"
                     alt="">
              </div>
              <h3>VIP Class</h3>
              <p>Class opening time and teaching hours can be flexibly customized</p>
              <p>50 minutes/Teaching Hour</p>
              <p>Support <strong>Skype</strong> and <strong> Wechat </strong> Teaching</p>
              <p></p>
              <router-link to=''
                           @click="onShowForm('VIP Class')">Registration</router-link>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="lis">
              <div class="imgbox">
                <img src="@/assets/A02.jpg"
                     alt="">
              </div>
              <h3>Small Class (3-5 students)</h3>
              <p>4-20 teaching hours/week</p>
              <p>50 minutes/Teaching Hour</p>
              <p>Get enrolled every month</p>
              <p>Support <strong>Skype</strong> and <strong> Wechat </strong> Teaching</p>
              <p></p>
              <router-link to=''
                           @click="onShowForm('Small Class (3-5 students)')">Registration</router-link>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="lis">
              <div class="imgbox">
                <img src="@/assets/A03.jpg"
                     alt="">
              </div>
              <h3>Standard Class (8-20 students)</h3>
              <p>4-20 teaching hours/week</p>
              <p>50 minutes/Teaching Hour</p>
              <p>Get enrolled every month</p>
              <p>NO <strong>Skype</strong> and <strong> Wechat </strong> Teaching</p>
              <p>20 Students Maximum</p>
              <router-link to=''
                           @click="onShowForm('Standard Class (8-20 students)')">Registration</router-link>
            </div>
          </el-col>
        </el-row>
        <div class="flw pian">
          <div class="_lis">
            <div class="pds">
              <h3>
                ZHUXUE has been committed to <br>
                Help to improve the language application ability of the whole society
              </h3>
              <p>In your daily study and work, you may encounter various document writing problems, such as formal business letters, resumes and other application materials, legal documents, meeting speeches, reports, etc. If you are not confident in your writing skills or think that the content you write is lack of highlights, you can ask the language teachers to help you guide, correct and polish, We are always ready to provide you with language assistance to make your writing more brilliant.</p>
            </div>
          </div>
          <div class="_lis">
            <div class="imgbox">
              <img src="@/assets/lx001.jpg"
                   alt="">
            </div>
          </div>
          <div class="_lis">
            <div class="imgbox">
              <img src="@/assets/lx002.jpg"
                   alt="">
            </div>
          </div>
          <div class="_lis">
            <div class="pds">
              <h3>
                ZHUXUE has been committed to <br>
                Becoming Chinese culture disseminator in Chinese International Education Industry
              </h3>
              <p>As a member of the Chinese international education industry, a disseminator of Chinese culture, and a partner of Hanban and many colleges and universities for international exchange, ZHUXUE has always been committed to providing real research materials and data for Chinese and foreign students majoring in Chinese, We are also willing to provide special guidance on Chinese article or thesis writing for foreign students in China and global Chinese learners.</p>
            </div>
          </div>
        </div>
        <p class="text-cen pian">
          If you come to live and work in China and encounter any Chinese communication problems and other life problems in the process of travel, shopping, conversation, renting, etc,<br>
          Or if you are a foreign traveller OR worker in China and encounter language barriers overseas, please don't hesitate,
        </p>
        <h3 class="text-cen">
          Call ZHUXUE hotline immediately (09:00-17:30 Beijing time Monday - Friday)
          <span>+86-(0)10-62040776</span>
        </h3>
        <p class="text-cen">
          Experience ZHUXUE service<br>
          At present, the service is only open to registered members of the official website of ZHUXEU and students in various learning centers for free (only supports Chinese and English currently).
        </p>
        <div class="tit">
          Chinese Corner
        </div>
        <p class="text-cen">Our Chinese corner is a mini international language and cultural exchange activity. You, there, can not only practice oral English, but also make new friends at home and abroad * All language corners are 30 yuan / 1.5 hours, and ZHUXUE members enjoy free one year.</p>
      </div>
    </div>

    <el-dialog v-model="ShowForm"
               title=""
               width="50%">
      <div>

        <h3 class="formHead">{{formHead}}</h3>
        <el-form ref="form"
                 :model="form"
                 :rules="rules"
                 label-width="100px">
          <el-form-item label="Your name"
                        prop="cert_name">
            <el-input v-model="form.cert_name"></el-input>
          </el-form-item>
          <el-form-item label="Phone"
                        prop="phone">
            <el-input v-model="form.phone"></el-input>
          </el-form-item>
          <el-form-item label="E-mail"
                        prop="email">
            <el-input v-model="form.email"></el-input>
          </el-form-item>
          <div style="margin-bottom:20px">
            Note: Please leave your message, our consultant will contact you soon ASAP. <br>
            * Working Hours (Beijing time 9:00am-5:30pm Monday-Friday, except national holidays)
          </div>
          <el-button type="primary"
                     @click="onSubmit('form')">Submit</el-button>
        </el-form>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="ShowForm = false">Cancel</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>


<script>
import axiosApi from "../api/api.js";
import { ElMessage } from 'element-plus'
export default {
  data () {
    return {
      ShowForm: false,
      formHead: '',
      form: {
        cert_name: '',
        phone: '',
        email: '',

      },
      rules: {
        cert_name: [
          {
            required: true,
            message: 'Please input Id name',
            trigger: 'blur',
          },
          {
            min: 2,
            max: 64,
            message: 'Length should be 3 to 64',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input your email',
            trigger: 'blur',
          },
          {
            type: 'email',
            message: 'Please input correct email address',
            trigger: ['blur', 'change'],
          },
        ],
        phone: [
          {
            required: true,
            message: 'Please input your phone',
            trigger: 'blur',
          },
        ],
      },
      activeName: 'first',
      tableData1: [
        {
          val1: '',
          val2: '',
          val3: '0',
          val4: '0',
          val5: "Can't speak or understand Chinese",
          val6: 'RMB',
          val7: 'USD',
        },
        {
          val1: 'ZX1',
          val2: "1.5 Months (40 Teaching hours)",
          val3: 'A1',
          val4: 'HSK1',
          val5: 'Learn basic Chinese, be able to understand and use Chinese to answer some simple questions, and master 150 common words.',
          val6: '￥999',
          val7: '$149',
        },
        {
          val1: 'ZX2',
          val2: "3 Months (40 Teaching hours)",
          val3: 'A2',
          val4: 'HSK2',
          val5: 'Be able to have daily dialogue in Chinese, have simple and direct communication on common topics such as daily life, work, education, culture and feeling, and master 300 common words.',
          val6: '￥999',
          val7: '$149',
        },
        {
          val1: 'ZX3',
          val2: "6 Months (80 Teaching hours)",
          val3: 'B1',
          val4: 'HSK3',
          val5: 'Be able to communicate with native speakers of Chinese at a normal speed, expand the topics to science and technology, nature, home, communication and other aspects, skillfully use about 800 common Chinese characters, read simple articles without Pinyin, and send SMS and Wechat messages with Chinese and friends, and master 600 vocabularies.',
          val6: '￥1499',
          val7: '$229',

        },
        {
          val1: 'ZX4',
          val2: "10 Months (120 Teaching hours)",
          val3: 'B2',
          val4: 'HSK4',
          val5: 'Be able to use Chinese to talk about more complex topics such as economy, news, medical treatment, phenomena and employment. The expression is more standardized and appropriate. You can know more Chinese characters and have a vocabulary of 1200.',
          val6: '￥3999',
          val7: '$599',
        },
        {
          val1: 'ZX5',
          val2: "15 Months (180 Teaching hours)",
          val3: 'C1',
          val4: 'HSK5',
          val5: 'Be able to use Chinese to discuss, evaluate and express opinions on more abstract or professional topics such as culture, geography, experience and perception, literature and art, finance and trade. You can easily deal with various communication tasks. At the same time, you can also read general news and write in Chinese with a vocabulary of 2500.',
          val6: '￥7299',
          val7: '$1060',
        },
        {
          val1: 'ZX6',
          val2: "22 Months (240 Teaching hours)",
          val3: 'C2',
          val4: 'HSK6',
          val5: 'Be able to freely carry out various social communication activities in Chinese, use idioms, slang and other habitual expressions, pay attention to style and tone, and be able to read and write all kinds of articles more professionally. The application level of Chinese is close to that of native speakers, with a vocabulary of 5000.',
          val6: '￥11999',
          val7: '$1760',
        },
      ],
      tableData2: [
        {
          val1: '',
          val2: '',
          val3: '0',
          val4: '0',
          val5: "Can't speak or understand Chinese",
          val6: 'RMB',
          val7: 'USD',
        },
        {
          val1: 'ZXC1',
          val2: "2 Months (50 Teaching hours)",
          val3: 'A1',
          val4: '400汉字',
          val5: 'Be able to recognize and use pinyin to type / write some simple Chinese characters, understand the basic word formation of Chinese characters and the meaning of some commonly used components of Chinese characters , and recognize the Chinese characters in street signs, signs and advertisements.',
          val6: '￥1249',
          val7: '$189',
        },
        {
          val1: 'ZXC2',
          val2: "4.5 Months (60 Teaching hours)",
          val3: 'A2',
          val4: '800汉字',
          val5: 'Be able to basically understand and write daily Chinese email, SMS and Wechat content, and easily communicate with Chinese relatives and friends, colleagues, customers, waiters and housekeeping personnel in writing. The Chinese characters on menus, business cards and station displays can also be easily solved. Life in China is no longer difficult, and Chinese reaches HSK3 level.',
          val6: '￥1499',
          val7: '$229',
        },
      ],
      tableData3: [
        {
          val1: '',
          val2: '',
          val3: '0',
          val4: '0',
          val5: "Can't speak or understand Chinese",
          val6: 'RMB',
          val7: 'USD',
        },
        {
          val1: 'ZXB1',
          val2: "3 Months (60 Teaching hours)",
          val3: 'B1',
          val4: 'BCT(A)',
          val5: 'Be able to use Chinese to have general business communication, including introduction of goods, wishes, thanks, farewell, application, etc. you can also understand meeting notice, travel arrangement, simple quotation, income and expenditure statement, schedule, customs statement, visa form, etc; Learn some simple Chinese business etiquette and formal expressions etc.',
          val6: '￥1499',
          val7: '$229',
        },
        {
          val1: 'ZXB2',
          val2: '9 Months (200 Teaching hours)',
          val3: 'C1',
          val4: 'BCT(B)',
          val5: 'Be able to freely deal with various business occasions and conduct professional and authentic business Chinese communication; Be able to understand, analyze and comment on various financial and scientific news; Be able to read and write complaint letters, resumes, cover letters, contracts, project reports, work reports, business statements, rules and regulations and other formal business texts etc.',
          val6: '￥11999',
          val7: '$1760',
        },
      ]
    }
  },
  methods: {
    tableRowClassName ({ rowIndex }) {
      if (rowIndex === 0) {
        return 'warning-row'
      } else if (rowIndex === 1) {
        return 'warning-row2'
      }
      else if (rowIndex === 2) {
        return 'warning-row3'
      }
      else if (rowIndex === 3) {
        return 'warning-row4'
      }
      else if (rowIndex === 4) {
        return 'warning-row5'
      }
      else if (rowIndex === 5) {
        return 'warning-row6'
      }
      else if (rowIndex === 6) {
        return 'warning-row7'
      }
      return ''
    },
    onShowForm (val) {
      this.formHead = val
      this.ShowForm = true
    },
    onSubmit (formName) {
      console.log(this.form)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axiosApi('message', {
            form_type: 5,
            content: this.formHead,
            ...this.form
          }, 'post').then(res => {
            if (res.data.status) {
              ElMessage.success('success')
              this.ShowForm = false
              this.$refs[formName].resetFields()
            } else {
              ElMessage.error('error')
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  background: url(~@/assets/neibanner4.jpg) top center no-repeat;
  height: 400px;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .container {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    .title {
      font-size: 38px;
      font-weight: bold;
      color: #fff;
      letter-spacing: 4px;
    }
  }
}
.cont {
  .tit {
    border-left: 8px solid #0c3879;
    padding-left: 10px;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    line-height: 1;
  }
  .el-tabs ::v-deep {
    margin-top: 20px;
    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
    }
    .el-tabs__item.is-active,
    .el-tabs__item:hover {
      color: #0c3879;
    }
    .el-tabs__item {
      font-size: 16px;
      font-weight: bold;
    }
  }

  .ps {
    text-indent: 2em;
    line-height: 1.7;
  }
  .lis {
    width: 80%;
    margin: auto;
    border: 1px solid #dedede;
    .imgbox {
      margin-bottom: 10px;
    }
    h3 {
      font-size: 20px;
      margin: 20px 0;
      text-align: center;
      height: 57px;
    }
    p {
      border-top: 1px solid #dedede;
      border-bottom: 1px solid #dedede;
      line-height: 40px;
      min-height: 40px;
      margin-bottom: -1px;
      text-align: center;
    }
    a {
      display: block;
      width: max-content;
      padding: 10px 20px;
      background-color: #0c3879;
      color: #fff;
      margin: 20px auto;
      border-radius: 7px;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .flw {
    display: flex;
    flex-wrap: wrap;
  }
  .pian {
    margin-top: 60px !important;
  }
  ._lis {
    width: 50%;
    position: relative;
    overflow: hidden;
    .pds {
      background-color: #f1f1f1;
      padding: 40px;
    }
    h3 {
      color: #333;
      margin-bottom: 20px;
    }
    p {
      font-size: 14px;
      color: #666;
    }
    .imgbox {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .text-cen {
    text-align: center;
    line-height: 1.8;
  }
  p.text-cen {
    font-size: 16px;
    color: #484848;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  h3.text-cen {
    font-size: 20px;
    span {
      display: block;
      color: #0c3879;
      font-size: 30px;
    }
  }
  ._table {
    ._row {
      display: flex;
      align-items: center;
      margin-top: 10px;
      p {
        margin-left: 10px;
        color: #616161;
        font-size: 14px;
      }
      a {
        padding: 10px 20px;
        background-color: #0c3879;
        color: #fff;
        border-radius: 5px;
        margin-right: 10px;
        &:nth-child(2n) {
          background-color: #15a5e6;
        }
        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}
.el-table ::v-deep {
  .warning-row {
    --el-table-tr-background-color: #0c3879;
    color: #fff;
  }
  .el-table_3_column_15,
  .el-table_2_column_8,
  .el-table_1_column_1 {
    .cell {
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      background-color: #fff;
      color: #333;
    }
  }
  .el-table_3_column_17,
  .el-table_2_column_10,
  .el-table_1_column_3 {
    font-size: 50px;
    .cell {
      line-height: 1.7;
    }
  }
  .el-table_3_column_18,
  .el-table_2_column_11,
  .el-table_1_column_4 {
    font-size: 20px;
    font-weight: bold;
  }
  .warning-row > td.el-table__cell {
    &.el-table_3_column_15,
    &.el-table_2_column_8,
    &.el-table_1_column_1 {
      .cell {
        display: none;
      }
    }
    &.el-table_3_column_17,
    &.el-table_2_column_10,
    &.el-table_1_column_3 {
      .cell {
        display: none;
      }
    }
    &.el-table_3_column_18,
    &.el-table_2_column_11,
    &.el-table_1_column_4 {
      .cell {
        display: none;
      }
    }
  }
  .el-table_3_column_16,
  .el-table_2_column_9,
  .el-table_1_column_2 {
    .cell {
      font-weight: bold;
    }
  }
  .el-table_1_column_5 {
    .cell {
      word-wrap: break-word;
      word-break: normal;
    }
  }
  .warning-row:hover > td.el-table__cell {
    background-color: #0c3879;
    color: #fff;
  }
  .warning-row2 {
    --el-table-tr-background-color: #4bade6;
    color: #fff;
  }
  .warning-row2:hover > td.el-table__cell {
    background-color: #4bade6;
    color: #fff;
  }
  .warning-row3 {
    --el-table-tr-background-color: #9e9ed3;
    color: #fff;
  }
  .warning-row3:hover > td.el-table__cell {
    background-color: #9e9ed3;
    color: #fff;
  }
  .warning-row4 {
    --el-table-tr-background-color: #f3c650;
    color: #fff;
  }
  .warning-row4:hover > td.el-table__cell {
    background-color: #f3c650;
    color: #fff;
  }
  .warning-row5 {
    --el-table-tr-background-color: #bbcc42;
    color: #fff;
  }
  .warning-row5:hover > td.el-table__cell {
    background-color: #bbcc42;
    color: #fff;
  }
  .warning-row6 {
    --el-table-tr-background-color: #55bec9;
    color: #fff;
  }
  .warning-row6:hover > td.el-table__cell {
    background-color: #55bec9;
    color: #fff;
  }
  .warning-row7 {
    --el-table-tr-background-color: #4d86cb;
    color: #fff;
  }
  .warning-row7:hover > td.el-table__cell {
    background-color: #4d86cb;
    color: #fff;
  }
}
.formHead {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}
.el-form ::v-deep {
  .el-button {
    width: 100%;
  }
}
</style>